import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import { TwitterShareButton } from 'react-share';


const gridStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(275px, 1fr))', // Create as many columns as can fit in the available space
    gap: '16px',
    margin: '10px',
};
const BillionDollarDreams = (props) => {
    const { dreams } = props;

    const handleShare = async (text) => {

    };

    return (
        <Box sx={gridStyles}>
            {dreams.map((cardData, index) => (
                <Card key={index} variant="outlined" sx={{
                    backgroundColor: '#176F3D',
                    borderRadius: '10px',
                }}>
                    <CardContent sx={{ position: 'relative' }}>
                        <Typography variant="h3" component="div" sx={{ fontFamily: "Billabong" }}>
                            "{cardData.dream}"
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }} color="text.secondary" gutterTop>
                            -{cardData.name}
                        </Typography>
                        <Box sx={{
                            position: 'absolute',
                            bottom: '10px',
                            right: '10px',
                        }}>
                            <IconButton aria-label="share" onClick={() => handleShare(cardData.dream)}>
                                <TwitterShareButton
                                    url={`https://www.adollarforyourdreams.com`}
                                    title={`"${cardData.dream}" - ${cardData.name}`}
                                    via="dollar_4_dream"
                                    hashtags={['adollarforyourdreams', 'dreams', 'dreambig']}
                                >
                                    <Icon icon="mdi:twitter" />
                                </TwitterShareButton>
                            </IconButton>
                        </Box>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

export default BillionDollarDreams;