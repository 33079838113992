import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function About() {
    return (
        <div>
            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#176F3D',
                borderRadius: '10px',
                margin: 'auto',
                padding: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                marginTop: '30px',
                marginBottom: '30px',
            }}>
                <h1>The Story</h1>
                {/* A dollar for your dream was created to embrace following your dreams and aspiration */}
                <CardContent>
                    <Typography variant="p" component="div" sx={{ lineHeight: "2", fontSize:"1.2em"}}>
                        "A Dollar for Your Dreams" is more than just a website; it's a beacon of hope, a catalyst for ambition, and a platform designed with a single, powerful purpose: to empower you to chase your dreams relentlessly.

                        Our journey began with a simple yet profound belief: that dreams should never be bound by circumstances, but rather, they should soar freely, unrestrained by limitations. We've built this platform as a testament to that belief.

                        In a world that sometimes tries to stifle our passions and quiet our desires, we stand firmly in defiance. We say, 'Dream Big!' We say, 'Dream Loud!' We say, 'Dream Fearlessly!'

                        "A Dollar for Your Dreams" invites you to take that courageous step forward. For the price of just one dollar, you can declare your dreams to the universe. You can send a powerful message to yourself and to the world that your aspirations matter, that they are worth investing in, and that they are deserving of every chance to flourish.

                        Life is too short to let your dreams wither away in the recesses of your heart. Every one of us has a unique calling, a purpose waiting to be uncovered. We refuse to let anyone leave this world without discovering their true potential.

                        So, whether you dream of starting a business, writing a novel, traveling the world, or simply finding happiness in the small moments of life, "A Dollar for Your Dreams" is here to support you.

                        Join us in this incredible journey of self-discovery, determination, and fulfillment. Take that one small but impactful step. Invest in your dreams today because, my friend, your dreams are priceless, and they are the driving force behind a life well-lived.

                        Welcome to "A Dollar for Your Dreams," where every dream is cherished, and every dollar is an investment in your limitless potential. Together, we'll turn dreams into reality."
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}

export default About;