import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_live_51Nz1dtJU3g4xRrMqtn8kP9qZvyOIEhzr4hcqiCqUubxOyEeXUOP8jCpgZGUFoSXcuRasLlX0LeGMJ9wOp1jwtKz800vgW6FoeI');


export default function Navbar(props) {
    const [open, setOpen] = React.useState(false);
    const { refresh } = props;
    const location = useLocation();
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleToggleRefresh = () => {
        refresh((prev) => !prev);
    };

    const handlePostDream = () => {
        axios.post('https://adollarforyourdreams-6ed3ce9de789.herokuapp.com/user/dream', {
            dream: localStorage.getItem('dream'),
            name: localStorage.getItem('name')
        }).then(function (response) {
            console.log(response);
            setOpen(false);
            localStorage.removeItem('dream');
            localStorage.removeItem('name');
        }).catch(function (error) {
            console.log(error);
        });
    };

    const handleChangeDream = (event) => {
        localStorage.setItem('dream', event.target.value);
    };

    const handleChangeName = (event) => {
        localStorage.setItem('name', event.target.value);
    };

    const handleSubmit = async () => {
        axios.post('https://adollarforyourdreams-6ed3ce9de789.herokuapp.com/user/session')
            .then(async function (response) {
                console.log(response);
                const { session_id } = response.data;
                console.log(session_id);
                // When the customer clicks on the button, redirect them to Checkout.
                const stripe = await stripePromise;
                const result = await stripe.redirectToCheckout({
                    sessionId: session_id,
                });

                if (result.error) {
                    // return;
                    console.error(result.error.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (location.pathname === '/success') {
            if (localStorage.getItem('dream') === null || localStorage.getItem('name') === null) {
                navigate('/');
            } else {
                handlePostDream();

            }
        } else if (location.pathname === '/cancel') {
            localStorage.removeItem('dream');
            localStorage.removeItem('name');
        }

        return () => {
            handleToggleRefresh();
            localStorage.removeItem('dream');
            localStorage.removeItem('name');
        }
    }, [location.pathname === '/success']);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{
                backgroundColor: "#37382D"
            }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#E5E8C9", fontWeight: "700", fontFamily: "Billabong", fontSize: "2em" }}>
                        <Link to="/" style={{ textDecoration: 'none', color: "#E5E8C9" }}>A dollar for your dreams . com</Link>
                    </Typography>
                    <Button sx={{
                        color: "#E5E8C9",
                    }} color="inherit">
                        <Link to="/about" style={{ textDecoration: 'none', color: "#E5E8C9" }}>About</Link>
                    </Button>
                    <Button onClick={handleClickOpen} variant='contained' sx={{
                        backgroundColor: '#176F3D',
                        color: "#E5E8C9",
                        '&:hover': {
                            backgroundColor: '#176F3D',
                            opacity: 0.8,
                        }
                    }}>Post</Button>
                </Toolbar>
            </AppBar>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Post Your Dream</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Every great achievement begins with a dream. Your dreams are the compass guiding you to a future limited only by your imagination and determination. Don't hold back – let your dreams take flight here.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="What dream would you like to share?"
                        type="text"
                        onChange={(e) => handleChangeDream(e)}
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="What do you want to be called?"
                        type="text"
                        onChange={(e) => handleChangeName(e)}
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{
                        color: "#176F3D",
                    }}>Cancel</Button>
                    <Button variant="contained" onClick={handleSubmit} sx={{
                        backgroundColor: '#176F3D',
                        color: "#E5E8C9",
                        '&:hover': {
                            backgroundColor: '#176F3D',
                            opacity: 0.8,
                        }
                    }}
                    >Share your dream</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}