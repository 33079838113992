import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import BillionDollarDreams from './components/BillionDollarDreams';
import About from './components/About';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from 'axios';

function App() {
  const [dreams, setDreams] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    axios.get('https://adollarforyourdreams-6ed3ce9de789.herokuapp.com/user/dream')
      .then(function (response) {
        setDreams(response.data.dreams);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [refresh]);

  return (
    <BrowserRouter>
      <Navbar refresh={setRefresh} />
      <Routes>
        <Route path="/" element={<BillionDollarDreams dreams={dreams} />} />
        <Route path="/success" element={<BillionDollarDreams dreams={dreams} />} />
        <Route path="/cancel" element={<BillionDollarDreams dreams={dreams} />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
